import {RadJobAiProcedureDto} from 'src/app/interfaces/backend/dto/rad-job-ai-procedure-dto.interface';

export const parseRadJobAiProcedureDtoCost = (data: RadJobAiProcedureDto) => {
    const result: RadJobAiProcedureDto = {
        ...data,
        cost: (data.cost ?? 0) / 100
    };

    return result;
};

export const parseRadJobAiProcedureDtoListCost = (data: RadJobAiProcedureDto[]) => {
    const result: RadJobAiProcedureDto[] = data.map(entry => parseRadJobAiProcedureDtoCost(entry));
    return result;
};