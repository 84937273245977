import {Injectable, Injector} from '@angular/core';
import {catchError, concat, defaultIfEmpty, map, of, tap, throwError} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {RadConsultationRateDto} from 'src/app/interfaces/backend/dto/rad-consultation-rate-dto.interface';
import {RadJobAiProcedureDto} from 'src/app/interfaces/backend/dto/rad-job-ai-procedure-dto.interface';
import {CRUDResponsesCU} from 'src/app/interfaces/core/crud-responses.interface.ts.intrerface';
import {parseRadJobAiProcedureDtoListCost} from 'src/app/mappers/ai-procedures.mapper';
import {AdministrationsControllerService} from 'src/app/services/backend/administrations-controller.service';
import {RadiologyAiControllerService} from 'src/app/services/backend/radiology-ai-controller.service';
import {displaySuccess} from 'src/app/utilities/toast.util';

@Injectable({providedIn: 'root'})
export class AIProceduresFacade {
    public data: RadJobAiProcedureDto[] = [];

    constructor(
        private radiologyAiControllerService: RadiologyAiControllerService,
        private administrationsControllerService: AdministrationsControllerService,
        private injector: Injector
    ) {
    }

    public reset() {
        this.data = [];
    }

    public loadData(unitId: number, aiKey: string) {
        this.loadDataLogic(unitId, aiKey).subscribe();
    }

    public loadDataLogic(unitId: number, aiKey: string) {
        return this.getData(unitId, aiKey).pipe(
            tap(data => this.data = parseRadJobAiProcedureDtoListCost(data))
        );
    }

    public getData(unitId: number, aiKey: string) {
        return this.radiologyAiControllerService.getAssignedProcedures(unitId, aiKey);
    }

    public createAiProcedures(unitId: number, aiKey: string, radJobAiProcedureDtoList: RadJobAiProcedureDto[], callback?: (data: RadJobAiProcedureDto[]) => void) {
        return this.radiologyAiControllerService.createUnitAiProcedures(unitId, aiKey, radJobAiProcedureDtoList).pipe(
            tap(data => callback?.(data)),
            catchError(err => of([] as RadJobAiProcedureDto[]))
        );
    }

    public updateAiProcedures(unitId: number, aiKey: string, radJobAiProcedureDtoList: RadJobAiProcedureDto[], callback?: (data: RadConsultationRateDto[]) => void) {
        return this.radiologyAiControllerService.updateUnitAiProcedures(unitId, aiKey, radJobAiProcedureDtoList).pipe(
            tap(data => callback?.(data)),
            catchError(err => of([] as RadJobAiProcedureDto[]))
        );
    }

    public handleConsultationRates(unitId: number, aiKey: string, data: RadJobAiProcedureDto[], callback: (responses: CRUDResponsesCU<RadJobAiProcedureDto[], RadJobAiProcedureDto[]>) => void) {
        let error = false;
        const payloadCreate = data.filter(entry => !entry.id);
        const payloadUpdate = data.filter(entry => entry.id);

        const requestCreate = payloadCreate.length ?
            this.createAiProcedures(unitId, aiKey, payloadCreate).pipe(defaultIfEmpty([] as RadJobAiProcedureDto[])) :
            of([]);

        const requestUpdate = payloadUpdate.length ?
            this.updateAiProcedures(unitId, aiKey, payloadUpdate).pipe(defaultIfEmpty([] as RadJobAiProcedureDto[])) :
            of([]);

        let finalResponses: CRUDResponsesCU<RadJobAiProcedureDto[], RadJobAiProcedureDto[]> = {};

        concat(
            requestUpdate.pipe(map((response) => {
                const newResponse: CRUDResponsesCU<RadJobAiProcedureDto[], RadJobAiProcedureDto[]> = {responseUpdate: response};
                return newResponse;
            })),
            requestCreate.pipe(map((response) => {
                const newResponse: CRUDResponsesCU<RadJobAiProcedureDto[], RadJobAiProcedureDto[]> = {responseCreate: response};
                return newResponse;
            }))
        ).pipe(
            tap((responses: CRUDResponsesCU<RadJobAiProcedureDto[], RadJobAiProcedureDto[]>) => finalResponses = {...finalResponses, ...responses}),
            catchError((err) => {
                error = true;
                return throwError(() => err);
            }),
            finalize(() => {
                if (!error) displaySuccess(this.injector);
                this.getData(unitId, aiKey).subscribe(data => {
                    this.data = data ?? [];
                    callback(finalResponses);
                });
            })
        ).subscribe();
    }

    public deleteAiProcedures(unitId: number, aiKey: string, id: number, callback?: () => void) {
        this.radiologyAiControllerService.deleteUnitAiProcedure(unitId, aiKey, id).subscribe(() => callback?.());
    }
}